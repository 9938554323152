<template>
  <v-app>
    <v-navigation-drawer v-if="user" v-model="drawer" app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Gaming Corps Tools
          </v-list-item-title>
          <v-list-item-subtitle>
            Ultimate Toolkit for daily usage
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item v-for="t in tools" :key="t.code">
          <v-list-item-icon class="mr-2">
            <v-icon>{{ t.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ t.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-toolbar-title>
        <v-img
          alt="GamingCorps Logo"
          class="shrink mr-2"
          contain
          src="@/assets/logo.png"
          transition="scale-transition"
          width="150"/>
      </v-toolbar-title>

      <v-row justify="end">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" v-bind="attrs" v-on="on" @click="$store.commit('toggleTheme')">
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-btn>
          </template>
          <span>Toggle theme</span>
        </v-tooltip>
        <div v-if="!user" class="g_id_signin mr-2"></div>
        <v-tooltip v-if="user" left>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-if="user" color="white" class="mr-2 mt-0 text--primary" size="48" v-bind="attrs" v-on="on">
              <img v-if="user.picture" :src="user.picture" alt="Avatar" referrerpolicy="no-referrer">
              <span v-else>{{user.given_name.charAt(0)+user.family_name.charAt(0)}}</span>
            </v-avatar>
          </template>
          <span>{{ user.name }}</span>
        </v-tooltip>
        <v-tooltip v-if="user" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="user" icon class="mr-2" v-bind="attrs" v-on="on" @click="logout">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-row>
    </v-app-bar>

    <v-main v-if="user">
      <router-view/>
    </v-main>

    <v-container v-if="!user" fluid pa-0>
      <v-row align="center" justify="center"
             style="height:100vh" dense>
        <v-col cols="12" lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center">
          <v-card-text>Please, login first to get access to the bucket manager.</v-card-text>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!user" id="g_id_onload"
         data-client_id="997271528137-1ckr6vlit57dt1turji55i3jsrohua56.apps.googleusercontent.com"
         data-callback="onSignIn"
         data-auto_prompt="true">
    </div>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import jwt_decode from 'jwt-decode';

export default {
  name: 'App',

  data: () => ({
    drawer: false
  }),

  computed: mapState([
    'user', 'tool', 'tools', 'theme'
  ]),

  head: {
    title: {
      inner: 'GC Toolkit'
    },
    meta: [
      { name: 'google-signin-client_id', content: '997271528137-1ckr6vlit57dt1turji55i3jsrohua56.apps.googleusercontent.com' }
    ],
    script: [
      { type: 'text/javascript', src: 'https://accounts.google.com/gsi/client', async: true, defer: true },
    ]
  },

  watch: {
    theme(theme) {
      this.$vuetify.theme.dark = theme === 'dark';
    }
  },

  methods: {
    setBucket(bucket) {
      this.$store.commit('setBucket', bucket);
      this.drawer = false;

      setTimeout(() => {
        document.querySelector('.v-treeview-node__root:first-of-type').click();
      }, 500);
    },
    logout() {
      this.$store.commit('setUser', null);
      this.$router.go();
    }
  },

  mounted() {
    window.onSignIn = user => {
      this.$store.commit('setUser', jwt_decode(user.credential));
    };

    if (this.theme === 'dark') this.$vuetify.theme.dark = true;
  }
};
</script>

<style lang="scss">
  .g_id_signin {
    z-index: 100;
    position: relative;
  }

  header.v-app-bar {
    background: linear-gradient(90deg, #152F7C 0%, #44C9EF 100%);
  }

  .v-toast {
    font-family: "Roboto", sans-serif;
  }

  #app.theme--dark {
    @at-root body {
      background-color: #1E1E1E;
    }
  }
</style>
